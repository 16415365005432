import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Transition from '../utils/Transition.js';
import Logo from '../images/new-personal-logo.svg'
// import Dropdown from '../utils/Dropdown';
import LangSelector from '../components/langSelector.js';
import { useTranslation } from 'react-i18next';


function Header({isMention}) {


  const { t } = useTranslation();

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [top, setTop] = useState(true);

  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNavOpen.current) return;
      if (!mobileNavOpen || mobileNav.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  // detect whether user has scrolled the page down by 10px 
  useEffect(() => {
    if (!mobileNavOpen) {
      const scrollHandler = () => {
        window.pageYOffset > 10 ? setTop(false) : setTop(true)
      };
      window.addEventListener('scroll', scrollHandler);
      return () => window.removeEventListener('scroll', scrollHandler);
    }
  }, [top]);  

  return (
    <header className={`fixed w-full z-50 md:bg-opacity-90 transition duration-300 ease-in-out bg-white  ${!top && 'shadow-lg'}`}>
      
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">

          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" className="block" aria-label="Cruip">
                <img src={Logo} alt="logo" width={80} />
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:flex-grow">

            {/* Desktop menu links */}
            <ul className="flex flex-grow justify-center flex-wrap items-center">
              <li>
                {
                  isMention ? <Link to="/#a-propos" className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">{t('NAV-ABOUT')}</Link> :
                  <a href="#a-propos" className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">{t('NAV-ABOUT')}</a>

                }
              </li>
              <li>
              {
                  isMention ? <Link to="/#services" className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">{t('NAV-SERVICES')}</Link> :
                  <a href="#services" className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">{t('NAV-SERVICES')}</a>

                }              
                </li>
              <li>
                {
                  isMention ? <Link to="/#portfolio" className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">{t('NAV-PROJECT')}</Link> :
                  <a href="#portfolio" className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">{t('NAV-PROJECT')}</a>

                }                 
              </li>
              <li>
              {
                  isMention ? <Link to="/#contact" className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">{t('NAV-CONTACT')}</Link> :
                  <a href="#contact" className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">{t('NAV-CONTACT')}</a>

                }                
            </li>        
            </ul>
            <div>
               <LangSelector />
            </div>


            {/* Desktop sign in links */}
          </nav>

          {/* Mobile menu */}
          <div className="flex md:hidden">
            <LangSelector/>

            {/* Hamburger button */}
            <button
              className={`hamburger ${mobileNavOpen && 'active'}`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => {
                setMobileNavOpen(!mobileNavOpen)
                setTop(!top)
              }}
            >
              <span className="sr-only">Menu</span>
              <svg className="w-6 h-6 fill-current text-gray-900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect y="4" width="24" height="2" />
                <rect y="11" width="24" height="2" />
                <rect y="18" width="24" height="2" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <div ref={mobileNav}>
              <Transition
                show={mobileNavOpen}
                tag="nav"
                id="mobile-nav"
                className="absolute top-full h-100 pb-16 z-20 left-0 w-full overflow-scroll bg-white"
                enter="transition ease-out duration-200 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-200"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"              
              >
                <ul className="px-5 py-2">
                <li>
                <a href="#a-propos" className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">A propos</a>
              </li>
              <li>
                <a href="#services" className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">Services</a>
              </li>
              <li>
                <a href="#portfolio" className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">Portfolio</a>
              </li>
              <li>
                <a href="#contact" className="btn text-white bg-gradient-to-r from-blue-500 to-teal-400 w-full mb-4 sm:w-auto sm:mb-0">Contact</a>
              </li>  
                </ul>
              </Transition>
            </div>
          </div>

        </div>
      </div>
    </header>
  );
}

export default Header;

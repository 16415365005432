import React, { useReducer, useState } from 'react'

import { useWindowSize } from '../hooks/useWindowSize'

const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    message: '',
}

const initialErrors = {
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    messageError: false,
}

function Cta() {
    const windowSize = useWindowSize()

    const reducer = (currentState, nextState) => ({
        ...currentState,
        ...nextState,
    })

    const [errors, dispatchError] = useReducer(reducer, initialValues)
    const [values, dispatchValues] = useReducer(reducer, initialErrors)

    const [isSending, setIsSending] = useState(false)

    // after sending request
    const [isSuccess, setIsSuccess] = useState(false)
    const [isError, setIsError] = useState(false)

    /**
     * onChange handler
     * @param element
     *
     */
    const onChange = (e) => {
        console.log(e.target.value)

        dispatchValues({ [e.target.id]: e.target.value })
    }

    /**
     * focus handler
     * @param element
     */
    const onFocus = (e) => {
        console.log(e.target.value)

        dispatchValues({ [e.target.id]: false })
    }

    /**
     *
     * @param data
     * @returns URI string encoded
     */
    const encode = (data) => {
        return Object.keys(data)
            .map(
                (key) =>
                    encodeURIComponent(key) +
                    '=' +
                    encodeURIComponent(data[key])
            )
            .join('&')
    }

    /**
     * onSubmit handler
     * @param element
     */
    const onSubmit = (e) => {
        e.preventDefault()
        /**
         * Validation
         */

        setIsSending(true)

        for (const key in values) {
            if (!values[key]) {
                dispatchError({ [key]: true })
            }
        }

        console.log('here')

        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': e.target.getAttribute('name'),
                ...values,
            }),
        })
            .then(() => {
                setIsSending(false)
                setIsSuccess(true)
            })
            .catch((e) => {
                setIsSending(false)
                setIsError(true)
            })
    }

    return (
        <section id="contact">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pt-32 pb-12 md:pt-40 md:pb-20">
                    {windowSize.width > 950 && (
                        <svg
                            width="339"
                            height="295"
                            viewBox="0 0 339 295"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mx-auto"
                        >
                            <g
                                id="undraw_developer_activity_re_39tg 1"
                                clipPath="url(#clip0_101_4)"
                            >
                                <path
                                    id="Vector"
                                    d="M305.656 153.523C305.7 190.026 291.577 225.125 266.257 251.441C264.73 253.037 263.163 254.596 261.554 256.116C259.102 258.445 256.569 260.684 253.953 262.833C229.482 282.976 198.932 294.295 167.232 294.964C166.176 294.988 165.115 295 164.051 295C138.77 295 106.972 281.509 78.1152 262.833C75.7277 261.288 73.3616 259.707 71.0166 258.091C69.9206 257.342 68.8298 256.583 67.7444 255.813C56.6148 247.961 46.0506 239.339 36.1297 230.009C29.6341 223.919 23.543 217.412 17.8951 210.529C-4.27746 183.205 -9.16793 158.584 22.4428 153.523C26.1853 152.925 29.8177 152.287 33.3397 151.607C38.4414 150.628 43.3239 149.57 47.9872 148.434C54.2687 146.909 60.1702 145.255 65.6917 143.472C67.5491 142.875 69.3639 142.266 71.1362 141.644C97.3383 132.45 114.875 120.46 126.813 107.451C128.837 105.249 130.704 103.02 132.413 100.765C135.027 97.3323 137.401 93.724 139.519 89.9657C142.442 84.7436 144.898 79.2736 146.857 73.6195L146.964 73.3089C156.462 45.9364 153.573 20.5471 164.051 12.0494C208.843 -24.2786 264.607 27.4559 290.781 88.4644C292.1 91.5386 293.341 94.6313 294.504 97.7425C295.026 99.1323 295.529 100.525 296.014 101.92C297.29 105.555 298.447 109.2 299.486 112.854C299.884 114.248 300.264 115.64 300.626 117.031C301.012 118.533 301.383 120.03 301.726 121.527C304.272 132.49 305.656 143.301 305.656 153.523Z"
                                    fill="url(#paint0_linear_101_4)"
                                />
                                <path
                                    id="Vector_2"
                                    d="M167.232 294.964C166.176 294.988 165.115 295 164.051 295C138.77 295 106.972 281.509 78.1152 262.833C75.7277 261.288 73.3615 259.707 71.0166 258.091L73.0732 245.587L135.334 237.328L166.582 261.201L167.232 294.964Z"
                                    fill="#2F2E41"
                                />
                                <path
                                    id="Vector_3"
                                    d="M170.229 71.6962H161.357C161.082 71.6963 160.818 71.8054 160.624 71.9995C160.43 72.1936 160.32 72.4569 160.32 72.7314V81.5994C160.32 81.7351 160.347 81.8695 160.4 81.9948C160.452 82.1202 160.528 82.234 160.624 82.3298C160.721 82.4256 160.835 82.5015 160.96 82.5531C161.086 82.6048 161.221 82.6311 161.357 82.6308H170.229C170.503 82.6309 170.766 82.5224 170.96 82.329C171.154 82.1357 171.264 81.8733 171.265 81.5994V72.7314C171.264 72.4571 171.155 72.1942 170.961 72.0003C170.766 71.8063 170.503 71.697 170.229 71.6962V71.6962Z"
                                    fill="white"
                                />
                                <path
                                    id="Vector_4"
                                    d="M170.229 101.92H161.357C161.082 101.92 160.818 102.029 160.624 102.223C160.43 102.417 160.32 102.68 160.32 102.955V111.819C160.32 111.955 160.347 112.09 160.399 112.216C160.451 112.341 160.527 112.455 160.623 112.552C160.719 112.648 160.834 112.724 160.96 112.776C161.085 112.828 161.22 112.855 161.357 112.854H170.229C170.504 112.854 170.767 112.745 170.961 112.551C171.156 112.357 171.265 112.094 171.265 111.819V102.955C171.264 102.681 171.155 102.418 170.961 102.224C170.766 102.03 170.503 101.92 170.229 101.92V101.92Z"
                                    fill="#E6E6E6"
                                />
                                <path
                                    id="Vector_5"
                                    d="M171.249 117.884C171.205 117.645 171.079 117.43 170.893 117.274C170.706 117.118 170.472 117.033 170.229 117.031H161.357C161.082 117.032 160.818 117.141 160.624 117.335C160.43 117.529 160.32 117.792 160.32 118.067V126.931C160.32 127.067 160.347 127.202 160.399 127.327C160.451 127.453 160.527 127.567 160.623 127.663C160.719 127.76 160.834 127.836 160.96 127.888C161.085 127.94 161.22 127.966 161.357 127.966H170.229C170.504 127.966 170.767 127.857 170.961 127.663C171.156 127.469 171.265 127.205 171.265 126.931V118.067C171.266 118.005 171.261 117.944 171.249 117.884V117.884Z"
                                    fill="#E6E6E6"
                                />
                                <path
                                    id="Vector_6"
                                    d="M170.632 132.223C170.504 132.17 170.367 132.143 170.229 132.143H161.357C161.221 132.143 161.086 132.17 160.96 132.222C160.834 132.273 160.72 132.35 160.624 132.446C160.527 132.542 160.451 132.656 160.399 132.782C160.347 132.908 160.321 133.043 160.321 133.179V142.043C160.32 142.179 160.347 142.314 160.398 142.439C160.45 142.565 160.527 142.68 160.623 142.776C160.719 142.872 160.834 142.949 160.96 143C161.086 143.052 161.221 143.079 161.357 143.078H170.229C170.504 143.078 170.768 142.969 170.962 142.775C171.156 142.58 171.265 142.317 171.266 142.043V133.179C171.265 132.975 171.205 132.776 171.092 132.606C170.979 132.436 170.819 132.303 170.632 132.223Z"
                                    fill="#E6E6E6"
                                />
                                <path
                                    id="Vector_7"
                                    d="M188.866 132.143H179.994C179.858 132.143 179.723 132.17 179.597 132.222C179.471 132.273 179.357 132.35 179.26 132.446C179.164 132.542 179.088 132.656 179.036 132.782C178.984 132.908 178.957 133.043 178.958 133.179V142.043C178.957 142.179 178.984 142.313 179.036 142.439C179.088 142.565 179.164 142.679 179.26 142.775C179.357 142.871 179.471 142.948 179.597 143C179.723 143.052 179.858 143.078 179.994 143.078H188.866C189.141 143.078 189.404 142.969 189.599 142.775C189.793 142.58 189.902 142.317 189.902 142.043V133.179C189.902 132.904 189.793 132.641 189.599 132.447C189.404 132.252 189.141 132.143 188.866 132.143V132.143Z"
                                    fill="#E6E6E6"
                                />
                                <path
                                    id="Vector_8"
                                    d="M226.14 86.8079H217.268C216.993 86.808 216.73 86.9172 216.536 87.1113C216.341 87.3054 216.232 87.5687 216.232 87.8432V96.7112C216.232 96.8469 216.259 96.9813 216.311 97.1066C216.363 97.232 216.44 97.3458 216.536 97.4416C216.632 97.5374 216.746 97.6133 216.872 97.6649C216.998 97.7165 217.132 97.7429 217.268 97.7425H226.14C226.415 97.7426 226.678 97.6342 226.872 97.4408C227.066 97.2475 227.176 96.9851 227.177 96.7112V87.8432C227.176 87.5689 227.066 87.306 226.872 87.112C226.678 86.918 226.415 86.8087 226.14 86.8079V86.8079Z"
                                    fill="white"
                                />
                                <path
                                    id="Vector_9"
                                    d="M226.14 101.92H217.268C216.993 101.92 216.73 102.029 216.536 102.223C216.341 102.417 216.232 102.68 216.232 102.955V111.819C216.232 111.955 216.258 112.09 216.31 112.216C216.362 112.341 216.439 112.455 216.535 112.552C216.631 112.648 216.745 112.724 216.871 112.776C216.997 112.828 217.132 112.855 217.268 112.854H226.14C226.415 112.854 226.679 112.745 226.873 112.551C227.067 112.357 227.177 112.094 227.177 111.819V102.955C227.176 102.681 227.066 102.418 226.872 102.224C226.678 102.03 226.415 101.92 226.14 101.92V101.92Z"
                                    fill="white"
                                />
                                <path
                                    id="Vector_10"
                                    d="M244.778 71.6962H235.901C235.627 71.6971 235.365 71.8066 235.171 72.0007C234.978 72.1947 234.869 72.4575 234.869 72.7314V81.5994C234.869 81.8729 234.978 82.1352 235.172 82.3286C235.365 82.522 235.628 82.6307 235.901 82.6308H244.778C245.052 82.6309 245.315 82.5224 245.509 82.3291C245.703 82.1357 245.813 81.8733 245.814 81.5994V72.7314C245.813 72.4571 245.704 72.1942 245.509 72.0003C245.315 71.8063 245.052 71.697 244.778 71.6962Z"
                                    fill="white"
                                />
                                <path
                                    id="Vector_11"
                                    d="M244.778 86.8079H235.901C235.627 86.8089 235.365 86.9184 235.171 87.1125C234.978 87.3065 234.869 87.5693 234.869 87.8432V96.7112C234.869 96.9847 234.978 97.247 235.172 97.4404C235.365 97.6338 235.628 97.7425 235.901 97.7425H244.778C245.052 97.7426 245.315 97.6342 245.509 97.4408C245.703 97.2475 245.813 96.9851 245.814 96.7112V87.8432C245.813 87.5689 245.704 87.306 245.51 87.112C245.315 86.918 245.052 86.8087 244.778 86.8079V86.8079Z"
                                    fill="white"
                                />
                                <path
                                    id="Vector_12"
                                    d="M244.778 101.92H235.901C235.627 101.921 235.365 102.03 235.171 102.224C234.978 102.418 234.869 102.681 234.869 102.955V111.819C234.869 111.955 234.895 112.089 234.947 112.215C234.999 112.34 235.075 112.454 235.17 112.551C235.266 112.647 235.38 112.723 235.506 112.775C235.631 112.827 235.766 112.854 235.901 112.854H244.778C245.052 112.854 245.316 112.745 245.51 112.551C245.705 112.357 245.814 112.094 245.814 111.819V102.955C245.813 102.681 245.704 102.418 245.51 102.224C245.315 102.03 245.052 101.92 244.778 101.92V101.92Z"
                                    fill="white"
                                />
                                <path
                                    id="Vector_13"
                                    d="M263.415 71.6962H254.539C254.264 71.6971 254.002 71.8066 253.808 72.0007C253.615 72.1947 253.506 72.4575 253.506 72.7314V81.5994C253.506 81.8729 253.615 82.1352 253.809 82.3286C254.002 82.522 254.265 82.6307 254.539 82.6308H263.415C263.689 82.6309 263.952 82.5224 264.146 82.329C264.341 82.1357 264.45 81.8733 264.451 81.5994V72.7314C264.45 72.4571 264.341 72.1942 264.147 72.0003C263.953 71.8063 263.689 71.697 263.415 71.6962V71.6962Z"
                                    fill="white"
                                />
                                <path
                                    id="Vector_14"
                                    d="M263.415 86.8079H254.539C254.264 86.8089 254.002 86.9184 253.808 87.1125C253.615 87.3065 253.506 87.5693 253.506 87.8432V96.7112C253.506 96.9847 253.615 97.247 253.809 97.4404C254.002 97.6338 254.265 97.7425 254.539 97.7425H263.415C263.689 97.7426 263.952 97.6341 264.146 97.4408C264.341 97.2475 264.45 96.9851 264.451 96.7112V87.8432C264.45 87.5689 264.341 87.306 264.147 87.112C263.953 86.9181 263.689 86.8087 263.415 86.8079V86.8079Z"
                                    fill="white"
                                />
                                <path
                                    id="Vector_15"
                                    d="M263.415 132.143H254.539C254.403 132.143 254.268 132.17 254.143 132.222C254.017 132.275 253.903 132.351 253.808 132.447C253.712 132.543 253.636 132.657 253.584 132.783C253.532 132.908 253.506 133.043 253.506 133.179V142.043C253.506 142.178 253.532 142.313 253.584 142.438C253.636 142.564 253.712 142.678 253.808 142.774C253.903 142.87 254.017 142.947 254.143 142.999C254.268 143.051 254.403 143.078 254.539 143.078H263.415C263.69 143.078 263.953 142.969 264.147 142.775C264.342 142.58 264.451 142.317 264.451 142.043V133.179C264.451 132.904 264.342 132.641 264.147 132.447C263.953 132.252 263.69 132.143 263.415 132.143Z"
                                    fill="white"
                                />
                                <path
                                    id="Vector_16"
                                    d="M282.052 117.031H273.176C272.902 117.032 272.639 117.142 272.446 117.336C272.252 117.53 272.143 117.793 272.144 118.067V126.931C272.143 127.067 272.17 127.201 272.221 127.327C272.273 127.452 272.349 127.566 272.445 127.662C272.541 127.758 272.655 127.835 272.78 127.887C272.906 127.939 273.04 127.966 273.176 127.966H282.052C282.327 127.966 282.59 127.857 282.785 127.663C282.979 127.469 283.088 127.205 283.088 126.931V118.067C283.087 117.792 282.978 117.53 282.784 117.336C282.59 117.142 282.327 117.032 282.052 117.031V117.031Z"
                                    fill="white"
                                />
                                <g id="active-commit">
                                    <path
                                        id="Vector_17"
                                        d="M188.866 86.8079H179.994C179.719 86.808 179.455 86.9172 179.261 87.1113C179.067 87.3054 178.958 87.5687 178.958 87.8432V96.7112C178.958 96.8469 178.985 96.9813 179.037 97.1066C179.089 97.2319 179.165 97.3458 179.262 97.4416C179.358 97.5374 179.472 97.6132 179.598 97.6649C179.723 97.7165 179.858 97.7429 179.994 97.7425H188.866C189.14 97.7426 189.403 97.6342 189.597 97.4408C189.792 97.2475 189.901 96.9851 189.902 96.7112V87.8432C189.901 87.5689 189.792 87.306 189.598 87.112C189.404 86.918 189.141 86.8087 188.866 86.8079Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_18"
                                        d="M188.866 101.92H179.994C179.719 101.92 179.455 102.029 179.261 102.223C179.067 102.417 178.958 102.68 178.958 102.955V111.819C178.957 111.955 178.984 112.09 179.036 112.216C179.088 112.341 179.164 112.455 179.26 112.552C179.357 112.648 179.471 112.724 179.597 112.776C179.723 112.828 179.858 112.855 179.994 112.854H188.866C189.141 112.854 189.404 112.745 189.599 112.551C189.793 112.357 189.902 112.094 189.902 111.819V102.955C189.901 102.681 189.792 102.418 189.598 102.224C189.404 102.03 189.141 101.92 188.866 101.92V101.92Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_19"
                                        d="M188.866 117.031H179.994C179.776 117.032 179.565 117.1 179.389 117.227C179.213 117.355 179.081 117.534 179.013 117.74C178.976 117.845 178.958 117.956 178.958 118.067V126.931C178.957 127.067 178.984 127.202 179.036 127.327C179.088 127.453 179.164 127.567 179.26 127.663C179.357 127.76 179.471 127.836 179.597 127.888C179.723 127.94 179.858 127.966 179.994 127.966H188.866C189.141 127.966 189.404 127.857 189.599 127.663C189.793 127.469 189.902 127.205 189.902 126.931V118.067C189.901 117.792 189.792 117.53 189.598 117.336C189.404 117.142 189.141 117.032 188.866 117.031V117.031Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_20"
                                        d="M207.503 71.6962H198.631C198.356 71.6963 198.093 71.8054 197.898 71.9995C197.704 72.1936 197.595 72.4569 197.595 72.7314V81.5994C197.595 81.7351 197.622 81.8695 197.674 81.9948C197.726 82.1202 197.803 82.234 197.899 82.3298C197.995 82.4256 198.109 82.5015 198.235 82.5531C198.361 82.6048 198.495 82.6311 198.631 82.6308H207.503C207.777 82.6309 208.04 82.5224 208.235 82.329C208.429 82.1357 208.539 81.8733 208.54 81.5994V72.7314C208.539 72.4571 208.429 72.1942 208.235 72.0003C208.041 71.8063 207.778 71.697 207.503 71.6962V71.6962Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_21"
                                        d="M207.503 86.8079H198.631C198.356 86.808 198.093 86.9172 197.898 87.1113C197.704 87.3054 197.595 87.5687 197.595 87.8432V96.7112C197.595 96.8469 197.622 96.9813 197.674 97.1066C197.726 97.2319 197.803 97.3458 197.899 97.4416C197.995 97.5374 198.109 97.6132 198.235 97.6649C198.361 97.7165 198.495 97.7429 198.631 97.7425H207.503C207.777 97.7426 208.04 97.6342 208.235 97.4408C208.429 97.2475 208.539 96.9851 208.54 96.7112V87.8432C208.539 87.5689 208.429 87.306 208.235 87.112C208.041 86.918 207.778 86.8087 207.503 86.8079Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_22"
                                        d="M207.503 117.031H198.631C198.356 117.032 198.093 117.141 197.898 117.335C197.704 117.529 197.595 117.792 197.595 118.067V126.931C197.594 127.067 197.621 127.202 197.673 127.327C197.725 127.453 197.801 127.567 197.898 127.663C197.994 127.76 198.108 127.836 198.234 127.888C198.36 127.94 198.495 127.966 198.631 127.966H207.503C207.778 127.966 208.042 127.857 208.236 127.663C208.43 127.469 208.539 127.205 208.54 126.931V118.067C208.539 117.792 208.429 117.53 208.235 117.336C208.041 117.142 207.778 117.032 207.503 117.031V117.031Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_23"
                                        d="M207.503 132.143H198.631C198.495 132.143 198.36 132.17 198.234 132.222C198.108 132.273 197.994 132.35 197.898 132.446C197.801 132.542 197.725 132.656 197.673 132.782C197.621 132.908 197.594 133.043 197.595 133.179V142.043C197.594 142.179 197.621 142.313 197.673 142.439C197.725 142.565 197.801 142.679 197.898 142.775C197.994 142.871 198.108 142.948 198.234 143C198.36 143.052 198.495 143.078 198.631 143.078H207.503C207.778 143.078 208.042 142.969 208.236 142.775C208.43 142.58 208.539 142.317 208.54 142.043V133.179C208.539 132.904 208.43 132.641 208.236 132.447C208.042 132.252 207.778 132.143 207.503 132.143V132.143Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_24"
                                        d="M226.14 71.6962H217.268C216.993 71.6963 216.73 71.8054 216.536 71.9995C216.341 72.1936 216.232 72.4569 216.232 72.7314V81.5994C216.232 81.7351 216.259 81.8695 216.311 81.9948C216.363 82.1202 216.44 82.234 216.536 82.3298C216.632 82.4256 216.746 82.5015 216.872 82.5531C216.998 82.6048 217.132 82.6312 217.268 82.6308H226.14C226.415 82.6309 226.678 82.5224 226.872 82.329C227.066 82.1357 227.176 81.8733 227.177 81.5994V72.7314C227.176 72.4571 227.066 72.1942 226.872 72.0003C226.678 71.8063 226.415 71.697 226.14 71.6962V71.6962Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_25"
                                        d="M226.14 132.143H217.268C217.132 132.143 216.997 132.17 216.871 132.222C216.745 132.273 216.631 132.35 216.535 132.446C216.439 132.542 216.362 132.656 216.31 132.782C216.258 132.908 216.232 133.043 216.232 133.179V142.043C216.232 142.179 216.258 142.313 216.31 142.439C216.362 142.565 216.439 142.679 216.535 142.775C216.631 142.871 216.745 142.948 216.871 143C216.997 143.052 217.132 143.078 217.268 143.078H226.14C226.415 143.078 226.679 142.969 226.873 142.775C227.067 142.58 227.177 142.317 227.177 142.043V133.179C227.177 132.904 227.067 132.641 226.873 132.447C226.679 132.252 226.415 132.143 226.14 132.143V132.143Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_26"
                                        d="M244.778 117.031H235.901C235.627 117.032 235.365 117.142 235.171 117.336C234.978 117.53 234.869 117.793 234.869 118.067V126.931C234.869 127.067 234.895 127.201 234.947 127.327C234.999 127.452 235.075 127.566 235.17 127.662C235.266 127.758 235.38 127.835 235.506 127.887C235.631 127.939 235.766 127.966 235.901 127.966H244.778C245.052 127.966 245.316 127.857 245.51 127.663C245.705 127.469 245.814 127.205 245.814 126.931V118.067C245.813 117.792 245.704 117.53 245.51 117.336C245.315 117.142 245.052 117.032 244.778 117.031V117.031Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_27"
                                        d="M263.415 101.92H254.539C254.264 101.921 254.002 102.03 253.808 102.224C253.615 102.418 253.506 102.681 253.506 102.955V111.819C253.506 111.955 253.532 112.089 253.584 112.215C253.636 112.34 253.712 112.454 253.808 112.551C253.903 112.647 254.017 112.723 254.143 112.775C254.268 112.827 254.403 112.854 254.539 112.854H263.415C263.69 112.854 263.953 112.745 264.147 112.551C264.342 112.357 264.451 112.094 264.451 111.819V102.955C264.45 102.681 264.341 102.418 264.147 102.224C263.952 102.03 263.689 101.92 263.415 101.92V101.92Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_28"
                                        d="M282.052 71.6962H273.176C272.902 71.6971 272.639 71.8066 272.446 72.0007C272.252 72.1947 272.143 72.4575 272.144 72.7314V81.5994C272.144 81.8729 272.252 82.1352 272.446 82.3286C272.64 82.522 272.902 82.6307 273.176 82.6308H282.052C282.326 82.6309 282.589 82.5224 282.784 82.329C282.978 82.1357 283.087 81.8733 283.088 81.5994V72.7314C283.088 72.4571 282.978 72.1942 282.784 72.0003C282.59 71.8063 282.327 71.697 282.052 71.6962V71.6962Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_29"
                                        d="M282.052 86.8079H273.176C272.902 86.8089 272.639 86.9184 272.446 87.1125C272.252 87.3065 272.143 87.5693 272.144 87.8432V96.7112C272.144 96.9847 272.252 97.247 272.446 97.4404C272.64 97.6338 272.902 97.7425 273.176 97.7425H282.052C282.326 97.7426 282.589 97.6341 282.784 97.4408C282.978 97.2475 283.087 96.9851 283.088 96.7112V87.8432C283.088 87.5689 282.978 87.306 282.784 87.112C282.59 86.9181 282.327 86.8087 282.052 86.8079Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_30"
                                        d="M282.052 132.143H273.176C273.04 132.143 272.906 132.17 272.78 132.222C272.655 132.275 272.541 132.351 272.445 132.447C272.349 132.543 272.273 132.657 272.221 132.783C272.17 132.908 272.143 133.043 272.144 133.179V142.043C272.143 142.178 272.17 142.313 272.221 142.438C272.273 142.564 272.349 142.678 272.445 142.774C272.541 142.87 272.655 142.947 272.78 142.999C272.906 143.051 273.04 143.078 273.176 143.078H282.052C282.327 143.078 282.59 142.969 282.785 142.775C282.979 142.58 283.088 142.317 283.088 142.043V133.179C283.088 132.904 282.979 132.641 282.785 132.447C282.59 132.252 282.327 132.143 282.052 132.143Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_31"
                                        d="M300.689 71.6962H291.813C291.538 71.6971 291.276 71.8066 291.082 72.0007C290.889 72.1947 290.78 72.4575 290.78 72.7314V81.5994C290.78 81.8729 290.889 82.1352 291.083 82.3286C291.276 82.522 291.539 82.6307 291.813 82.6308H300.689C300.963 82.6309 301.226 82.5224 301.42 82.329C301.614 82.1357 301.724 81.8733 301.725 81.5994V72.7314C301.724 72.4571 301.615 72.1942 301.421 72.0003C301.226 71.8063 300.963 71.697 300.689 71.6962Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_32"
                                        d="M300.689 86.8079H291.813C291.538 86.8089 291.276 86.9184 291.082 87.1125C290.889 87.3065 290.78 87.5693 290.78 87.8432V96.7112C290.78 96.9847 290.889 97.247 291.083 97.4404C291.276 97.6338 291.539 97.7425 291.813 97.7425H300.689C300.963 97.7426 301.226 97.6341 301.42 97.4408C301.614 97.2475 301.724 96.9851 301.725 96.7112V87.8432C301.724 87.5689 301.615 87.306 301.421 87.112C301.226 86.9181 300.963 86.8087 300.689 86.8079V86.8079Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_33"
                                        d="M300.689 117.031H291.813C291.538 117.032 291.276 117.142 291.082 117.336C290.889 117.53 290.78 117.793 290.78 118.067V126.931C290.78 127.067 290.806 127.201 290.858 127.327C290.91 127.452 290.986 127.566 291.082 127.662C291.177 127.758 291.291 127.835 291.417 127.887C291.542 127.939 291.677 127.966 291.813 127.966H300.689C300.964 127.966 301.227 127.857 301.421 127.663C301.616 127.469 301.725 127.205 301.725 126.931V118.067C301.724 117.792 301.615 117.53 301.421 117.336C301.226 117.142 300.963 117.032 300.689 117.031V117.031Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_34"
                                        d="M319.326 86.8079H310.45C310.176 86.8089 309.913 86.9184 309.72 87.1125C309.526 87.3065 309.418 87.5693 309.418 87.8432V96.7112C309.418 96.9847 309.527 97.247 309.72 97.4404C309.914 97.6338 310.176 97.7425 310.45 97.7425H319.326C319.601 97.7426 319.864 97.6341 320.058 97.4408C320.252 97.2475 320.362 96.9851 320.363 96.7112V87.8432C320.362 87.5689 320.252 87.306 320.058 87.112C319.864 86.9181 319.601 86.8087 319.326 86.8079V86.8079Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_35"
                                        d="M319.326 101.92H310.45C310.176 101.921 309.913 102.03 309.72 102.224C309.526 102.418 309.418 102.681 309.418 102.955V111.819C309.418 111.955 309.444 112.089 309.496 112.215C309.547 112.34 309.623 112.454 309.719 112.551C309.815 112.647 309.929 112.723 310.054 112.775C310.18 112.827 310.314 112.854 310.45 112.854H319.326C319.601 112.854 319.865 112.745 320.059 112.551C320.253 112.357 320.363 112.094 320.363 111.819V102.955C320.362 102.681 320.252 102.418 320.058 102.224C319.864 102.03 319.601 101.92 319.326 101.92V101.92Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_36"
                                        d="M319.326 117.031H310.45C310.176 117.032 309.913 117.142 309.72 117.336C309.526 117.53 309.418 117.793 309.418 118.067V126.931C309.418 127.067 309.444 127.201 309.496 127.327C309.547 127.452 309.623 127.566 309.719 127.662C309.815 127.758 309.929 127.835 310.054 127.887C310.18 127.939 310.314 127.966 310.45 127.966H319.326C319.601 127.966 319.865 127.857 320.059 127.663C320.253 127.469 320.363 127.205 320.363 126.931V118.067C320.362 117.792 320.252 117.53 320.058 117.336C319.864 117.142 319.601 117.032 319.326 117.031V117.031Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_37"
                                        d="M337.964 101.92H329.088C328.813 101.921 328.551 102.03 328.357 102.224C328.164 102.418 328.055 102.681 328.055 102.955V111.819C328.055 111.955 328.081 112.089 328.133 112.215C328.185 112.34 328.261 112.454 328.356 112.551C328.452 112.647 328.566 112.723 328.692 112.775C328.817 112.827 328.952 112.854 329.088 112.854H337.964C338.238 112.854 338.502 112.745 338.696 112.551C338.891 112.357 339 112.094 339 111.819V102.955C338.999 102.681 338.89 102.418 338.696 102.224C338.501 102.03 338.238 101.92 337.964 101.92V101.92Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_38"
                                        d="M300.689 132.143H291.813C291.677 132.143 291.542 132.17 291.417 132.222C291.291 132.275 291.177 132.351 291.082 132.447C290.986 132.543 290.91 132.657 290.858 132.783C290.806 132.908 290.78 133.043 290.78 133.179V142.043C290.78 142.178 290.806 142.313 290.858 142.438C290.91 142.564 290.986 142.678 291.082 142.774C291.177 142.87 291.291 142.947 291.417 142.999C291.542 143.051 291.677 143.078 291.813 143.078H300.689C300.964 143.078 301.227 142.969 301.421 142.775C301.616 142.58 301.725 142.317 301.725 142.043V133.179C301.725 132.904 301.616 132.641 301.421 132.447C301.227 132.252 300.964 132.143 300.689 132.143V132.143Z"
                                        fill="currentColor"
                                    />
                                </g>
                                <g id="active-incoming">
                                    <path
                                        id="Vector_39"
                                        d="M170.229 86.8079H161.357C161.082 86.808 160.819 86.9172 160.624 87.1113C160.43 87.3054 160.321 87.5687 160.321 87.8432V96.7112C160.321 96.8469 160.348 96.9813 160.4 97.1066C160.452 97.2319 160.529 97.3458 160.625 97.4416C160.721 97.5374 160.835 97.6132 160.961 97.6649C161.087 97.7165 161.221 97.7429 161.357 97.7425H170.229C170.503 97.7426 170.766 97.6342 170.961 97.4408C171.155 97.2475 171.265 96.9851 171.266 96.7112V87.8432C171.265 87.5689 171.155 87.306 170.961 87.112C170.767 86.918 170.504 86.8087 170.229 86.8079Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_40"
                                        d="M188.866 71.6962H179.994C179.719 71.6963 179.456 71.8054 179.262 71.9995C179.067 72.1936 178.958 72.4569 178.958 72.7314V81.5994C178.958 81.7351 178.985 81.8695 179.037 81.9948C179.089 82.1202 179.166 82.234 179.262 82.3298C179.358 82.4256 179.473 82.5015 179.598 82.5531C179.724 82.6048 179.858 82.6311 179.994 82.6308H188.866C189.141 82.6309 189.404 82.5224 189.598 82.329C189.792 82.1357 189.902 81.8733 189.903 81.5994V72.7314C189.902 72.4571 189.793 72.1942 189.598 72.0003C189.404 71.8063 189.141 71.697 188.866 71.6962V71.6962Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_41"
                                        d="M207.504 101.92H198.631C198.357 101.92 198.093 102.029 197.899 102.223C197.705 102.417 197.595 102.68 197.595 102.955V111.819C197.595 111.955 197.622 112.09 197.674 112.216C197.726 112.341 197.802 112.455 197.898 112.552C197.994 112.648 198.109 112.724 198.235 112.776C198.36 112.828 198.495 112.855 198.631 112.854H207.504C207.778 112.854 208.042 112.745 208.236 112.551C208.431 112.357 208.54 112.094 208.54 111.819V102.955C208.539 102.681 208.43 102.418 208.236 102.224C208.041 102.03 207.778 101.92 207.504 101.92V101.92Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_42"
                                        d="M226.141 117.031H217.269C216.994 117.032 216.73 117.141 216.536 117.335C216.342 117.529 216.233 117.792 216.232 118.067V126.931C216.232 127.067 216.259 127.202 216.311 127.327C216.363 127.453 216.439 127.567 216.535 127.663C216.632 127.76 216.746 127.836 216.872 127.888C216.998 127.94 217.133 127.966 217.269 127.966H226.141C226.416 127.966 226.679 127.857 226.874 127.663C227.068 127.469 227.177 127.205 227.177 126.931V118.067C227.176 117.792 227.067 117.53 226.873 117.336C226.679 117.142 226.416 117.032 226.141 117.031V117.031Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_43"
                                        d="M244.778 132.143H235.901C235.766 132.143 235.631 132.17 235.506 132.222C235.38 132.275 235.266 132.351 235.17 132.447C235.075 132.543 234.999 132.657 234.947 132.783C234.895 132.908 234.869 133.043 234.869 133.179V142.043C234.869 142.178 234.895 142.313 234.947 142.438C234.999 142.564 235.075 142.678 235.17 142.774C235.266 142.87 235.38 142.947 235.506 142.999C235.631 143.051 235.766 143.078 235.901 143.078H244.778C245.052 143.078 245.316 142.969 245.51 142.775C245.705 142.58 245.814 142.317 245.814 142.043V133.179C245.814 132.904 245.705 132.641 245.51 132.447C245.316 132.252 245.052 132.143 244.778 132.143V132.143Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_44"
                                        d="M263.415 117.031H254.539C254.265 117.032 254.002 117.142 253.809 117.336C253.615 117.53 253.507 117.793 253.507 118.067V126.931C253.506 127.067 253.533 127.201 253.585 127.327C253.636 127.452 253.712 127.566 253.808 127.662C253.904 127.758 254.018 127.835 254.143 127.887C254.269 127.939 254.403 127.966 254.539 127.966H263.415C263.69 127.966 263.954 127.857 264.148 127.663C264.342 127.469 264.451 127.205 264.452 126.931V118.067C264.451 117.792 264.341 117.53 264.147 117.336C263.953 117.142 263.69 117.032 263.415 117.031V117.031Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_45"
                                        d="M282.052 101.92H273.176C272.902 101.921 272.639 102.03 272.446 102.224C272.252 102.418 272.143 102.681 272.144 102.955V111.819C272.143 111.955 272.17 112.089 272.221 112.215C272.273 112.34 272.349 112.454 272.445 112.551C272.541 112.647 272.655 112.723 272.78 112.775C272.906 112.827 273.04 112.854 273.176 112.854H282.052C282.327 112.854 282.59 112.745 282.785 112.551C282.979 112.357 283.088 112.094 283.088 111.819V102.955C283.088 102.681 282.978 102.418 282.784 102.224C282.59 102.03 282.327 101.92 282.052 101.92V101.92Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_46"
                                        d="M300.689 101.92H291.813C291.539 101.921 291.276 102.03 291.083 102.224C290.889 102.418 290.781 102.681 290.781 102.955V111.819C290.78 111.955 290.807 112.089 290.858 112.215C290.91 112.34 290.986 112.454 291.082 112.551C291.178 112.647 291.292 112.723 291.417 112.775C291.543 112.827 291.677 112.854 291.813 112.854H300.689C300.964 112.854 301.228 112.745 301.422 112.551C301.616 112.357 301.725 112.094 301.726 111.819V102.955C301.725 102.681 301.615 102.418 301.421 102.224C301.227 102.03 300.964 101.92 300.689 101.92Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_47"
                                        d="M319.327 71.6962H310.451C310.177 71.6971 309.914 71.8066 309.72 72.0007C309.527 72.1947 309.418 72.4575 309.418 72.7314V81.5994C309.419 81.8729 309.527 82.1352 309.721 82.3286C309.914 82.522 310.177 82.6307 310.451 82.6308H319.327C319.601 82.6309 319.864 82.5224 320.058 82.329C320.253 82.1357 320.362 81.8733 320.363 81.5994V72.7314C320.362 72.4571 320.253 72.1942 320.059 72.0003C319.865 71.8063 319.601 71.697 319.327 71.6962V71.6962Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_48"
                                        d="M319.327 132.143H310.451C310.315 132.143 310.18 132.17 310.055 132.222C309.93 132.275 309.816 132.351 309.72 132.447C309.624 132.543 309.548 132.657 309.496 132.783C309.444 132.908 309.418 133.043 309.418 133.179V142.043C309.418 142.178 309.444 142.313 309.496 142.438C309.548 142.564 309.624 142.678 309.72 142.774C309.816 142.87 309.93 142.947 310.055 142.999C310.18 143.051 310.315 143.078 310.451 143.078H319.327C319.602 143.078 319.865 142.969 320.06 142.775C320.254 142.58 320.363 142.317 320.363 142.043V133.179C320.363 132.904 320.254 132.641 320.06 132.447C319.865 132.252 319.602 132.143 319.327 132.143V132.143Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_49"
                                        d="M337.964 71.6962H329.088C328.814 71.6971 328.551 71.8066 328.358 72.0007C328.164 72.1947 328.056 72.4575 328.056 72.7314V81.5994C328.056 81.8729 328.165 82.1352 328.358 82.3286C328.552 82.522 328.814 82.6307 329.088 82.6308H337.964C338.238 82.6309 338.501 82.5224 338.696 82.329C338.89 82.1357 339 81.8733 339.001 81.5994V72.7314C339 72.4571 338.89 72.1942 338.696 72.0003C338.502 71.8063 338.239 71.697 337.964 71.6962V71.6962Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_50"
                                        d="M337.964 86.8079H329.088C328.814 86.8089 328.551 86.9184 328.358 87.1125C328.164 87.3065 328.056 87.5693 328.056 87.8432V96.7112C328.056 96.9847 328.165 97.247 328.358 97.4404C328.552 97.6338 328.814 97.7425 329.088 97.7425H337.964C338.238 97.7426 338.501 97.6341 338.696 97.4408C338.89 97.2475 339 96.9851 339.001 96.7112V87.8432C339 87.5689 338.89 87.306 338.696 87.112C338.502 86.9181 338.239 86.8087 337.964 86.8079V86.8079Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_51"
                                        d="M337.964 117.031H329.088C328.814 117.032 328.551 117.142 328.358 117.336C328.164 117.53 328.056 117.793 328.056 118.067V126.931C328.055 127.067 328.082 127.201 328.133 127.327C328.185 127.452 328.261 127.566 328.357 127.662C328.453 127.758 328.567 127.835 328.692 127.887C328.818 127.939 328.952 127.966 329.088 127.966H337.964C338.239 127.966 338.502 127.857 338.697 127.663C338.891 127.469 339 127.205 339 126.931V118.067C339 117.792 338.89 117.53 338.696 117.336C338.502 117.142 338.239 117.032 337.964 117.031V117.031Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        id="Vector_52"
                                        d="M337.964 132.143H329.088C328.952 132.143 328.818 132.17 328.692 132.222C328.567 132.275 328.453 132.351 328.357 132.447C328.261 132.543 328.185 132.657 328.133 132.783C328.082 132.908 328.055 133.043 328.056 133.179V142.043C328.055 142.178 328.082 142.313 328.133 142.438C328.185 142.564 328.261 142.678 328.357 142.774C328.453 142.87 328.567 142.947 328.692 142.999C328.818 143.051 328.952 143.078 329.088 143.078H337.964C338.239 143.078 338.502 142.969 338.697 142.775C338.891 142.58 339 142.317 339 142.043V133.179C339 132.904 338.891 132.641 338.697 132.447C338.502 132.252 338.239 132.143 337.964 132.143V132.143Z"
                                        fill="currentColor"
                                    />
                                </g>
                                <path
                                    id="Vector_53"
                                    d="M133.957 262.834H133.711L133.922 262.806L133.957 262.834Z"
                                    fill="#2F2E41"
                                />
                                <path
                                    id="Vector_54"
                                    d="M183.163 137.423L183.075 137.885L182.09 143.078L175.287 178.996L174.884 181.11L174.039 185.582L172.884 191.682L170.321 205.201L169.587 209.076L169.312 210.529L169.217 211.039L168.455 215.049L168.224 216.259L166.367 230.009L164.745 241.991L170.173 247.415L166.937 250.648L164.812 262.805L164.816 262.833H78.1152C74.6078 260.563 71.1508 258.223 67.7444 255.813L68.4578 250.477C68.4578 250.477 70.913 240.948 69.8807 239.809C68.8524 238.67 66.5964 236.636 66.5964 236.636L69.4383 230.009L69.8329 229.09L69.1713 215.379L69.1553 215.049L68.9361 210.529L68.9082 209.948L68.8644 209.076L68.0234 191.682L68.0074 191.396L67.7524 186.123L67.5132 181.11L67.4096 178.996L66.3853 157.839L65.6918 143.472L65.0859 130.965L67.5212 130.14L93.5679 121.296L104.357 107.283L126.813 107.451L137.805 107.534L156.148 124.53L160.321 126.521L160.352 126.537L163.346 127.966L170.934 131.586L178.958 135.416L183.075 137.38L183.163 137.423Z"
                                    fill="white"
                                />
                                <path
                                    id="Vector_55"
                                    d="M107.259 222.018C105.613 222.655 103.848 222.924 102.087 222.805C100.327 222.687 98.6132 222.184 97.068 221.333C95.5227 220.481 94.1832 219.301 93.1437 217.877C92.1041 216.452 91.3899 214.817 91.0514 213.086L48.5801 207.066L63.3443 190.381L101.412 198.656C104.359 198.28 107.342 198.998 109.795 200.672C112.248 202.347 114.001 204.863 114.722 207.742C115.443 210.622 115.081 213.666 113.706 216.297C112.33 218.928 110.036 220.964 107.259 222.018V222.018Z"
                                    fill="#FFB6B6"
                                />
                                <path
                                    id="Vector_56"
                                    d="M88.3224 191.682C87.5357 190.686 86.422 189.999 85.1778 189.743L67.7522 186.124L65.9626 185.753C64.9226 185.536 63.9401 185.103 63.0795 184.48C62.2189 183.858 61.4995 183.061 60.9685 182.141C60.4177 181.176 60.0844 180.103 59.992 178.996C59.9089 177.989 60.0296 176.976 60.3467 176.017L66.3851 157.839L70.303 146.045C70.7779 144.622 71.0581 143.142 71.1361 141.644C71.3544 137.5 70.071 133.416 67.521 130.14C65.2792 127.261 62.1698 125.178 58.6522 124.2C55.1346 123.222 51.3954 123.401 47.987 124.709C47.8874 124.745 47.7837 124.784 47.6841 124.824C45.2398 125.799 43.0559 127.329 41.3052 129.293C39.5544 131.256 38.2846 133.599 37.5963 136.137L33.3395 151.607L24.559 183.499C23.68 186.695 23.5025 190.042 24.0384 193.312C24.5744 196.582 25.8113 199.698 27.6644 202.447C29.5175 205.195 31.9431 207.511 34.7752 209.236C37.6072 210.961 40.7789 212.054 44.073 212.441L69.1711 215.379L80.7854 216.741C82.1285 216.903 83.483 216.552 84.5791 215.761C85.6753 214.969 86.4326 213.793 86.7003 212.469C86.719 212.397 86.7337 212.323 86.7441 212.25L87.0231 210.529L89.3866 196.043L89.4105 195.895C89.5337 195.156 89.5005 194.399 89.3131 193.674C89.1257 192.948 88.7882 192.27 88.3224 191.682Z"
                                    fill="white"
                                />
                                <path
                                    id="Vector_57"
                                    d="M134.312 222.482C136.031 222.881 137.816 222.898 139.543 222.533C141.269 222.168 142.894 221.429 144.304 220.368C145.713 219.307 146.873 217.951 147.701 216.394C148.529 214.837 149.005 213.117 149.096 211.357L190.293 199.413L173.322 184.974L136.802 198.53C133.831 198.573 130.98 199.704 128.787 201.707C126.595 203.711 125.215 206.448 124.908 209.401C124.601 212.353 125.388 215.316 127.122 217.727C128.855 220.138 131.413 221.83 134.312 222.482V222.482Z"
                                    fill="#FFB6B6"
                                />
                                <path
                                    id="Vector_58"
                                    d="M210.748 172.696L191.154 127.644C189.551 123.945 186.689 120.933 183.075 119.142C181.958 118.592 180.784 118.165 179.575 117.868C179.388 117.82 179.201 117.776 179.013 117.74C176.45 117.181 173.79 117.23 171.249 117.884C168.926 118.48 166.755 119.559 164.877 121.05C163 122.541 161.458 124.41 160.352 126.537C160.34 126.554 160.329 126.573 160.32 126.592C159.085 128.979 158.428 131.623 158.403 134.31C158.377 136.997 158.983 139.652 160.173 142.062L160.416 142.548L174.262 170.331C174.735 171.281 175.002 172.32 175.044 173.38C175.087 174.44 174.904 175.497 174.509 176.482C174.114 177.467 173.515 178.357 172.751 179.094C171.987 179.832 171.076 180.399 170.077 180.76L169.105 181.11L151.615 187.422C150.303 187.89 149.224 188.848 148.604 190.095C147.985 191.342 147.874 192.78 148.295 194.107L148.339 194.247L153.242 209.92C153.266 209.992 153.289 210.063 153.317 210.135C153.36 210.27 153.414 210.402 153.477 210.529C153.998 211.697 154.919 212.64 156.074 213.19C157.229 213.74 158.543 213.859 159.778 213.528L169.216 211.039L171.141 210.529L195.514 204.098C198.721 203.252 201.707 201.723 204.268 199.616C206.828 197.509 208.903 194.875 210.349 191.893C211.796 188.91 212.581 185.651 212.649 182.338C212.718 179.025 212.069 175.736 210.748 172.696L210.748 172.696Z"
                                    fill="white"
                                />
                                <path
                                    id="Vector_59"
                                    d="M17.895 210.529C23.5429 217.412 29.6341 223.919 36.1297 230.009H216.272C218.007 230.012 219.671 230.702 220.898 231.929C222.125 233.155 222.815 234.818 222.816 236.552L223.076 282.158C234.158 277.071 244.536 270.576 253.953 262.833C256.567 260.687 259.101 258.448 261.554 256.116C263.16 254.594 264.728 253.036 266.257 251.441C277.721 239.509 287.005 225.662 293.686 210.529H17.895Z"
                                    fill="#3F3D56"
                                />
                                <path
                                    id="Vector_60"
                                    d="M266.257 251.441C264.73 253.037 263.162 254.596 261.554 256.116H223.207V251.441H266.257Z"
                                    fill="white"
                                />
                                <path
                                    id="Vector_61"
                                    d="M182.804 101.92C182.615 101.57 182.334 101.279 181.993 101.075C181.651 100.872 181.261 100.765 180.863 100.765H50.1954C49.6096 100.766 49.0482 101 48.6343 101.414C48.2204 101.828 47.9878 102.39 47.9873 102.975V181.11H183.075V102.975C183.077 102.606 182.984 102.242 182.804 101.92V101.92Z"
                                    fill="#3F3D56"
                                />
                                <path
                                    id="Vector_62"
                                    d="M47.8687 178.995V188.637C47.8686 189.036 47.9475 189.432 48.1006 189.802C48.2537 190.171 48.4781 190.506 48.761 190.789C49.0439 191.072 49.3797 191.296 49.7494 191.449C50.119 191.602 50.5152 191.681 50.9153 191.681H100.775V210.709H100.113C100.061 210.709 100.009 210.719 99.9614 210.739C99.9134 210.759 99.8697 210.788 99.833 210.825C99.7962 210.862 99.7671 210.905 99.7472 210.953C99.7273 211.001 99.7171 211.053 99.7171 211.105V212.428C99.7171 212.48 99.7273 212.532 99.7472 212.58C99.7671 212.627 99.7962 212.671 99.833 212.708C99.8697 212.745 99.9134 212.774 99.9614 212.794C100.009 212.813 100.061 212.824 100.113 212.824H131.065C131.117 212.824 131.169 212.813 131.217 212.794C131.265 212.774 131.308 212.745 131.345 212.708C131.382 212.671 131.411 212.627 131.431 212.58C131.451 212.532 131.461 212.48 131.461 212.428V211.105C131.461 211.053 131.451 211.001 131.431 210.953C131.411 210.905 131.382 210.862 131.345 210.825C131.308 210.788 131.265 210.759 131.217 210.739C131.169 210.719 131.117 210.709 131.065 210.709H130.403V191.681H180.263C180.663 191.681 181.059 191.602 181.429 191.449C181.798 191.296 182.134 191.072 182.417 190.789C182.7 190.506 182.924 190.171 183.078 189.802C183.231 189.432 183.309 189.036 183.309 188.637V178.995H47.8687Z"
                                    fill="#3F3D56"
                                />
                                <path
                                    id="Vector_63"
                                    d="M113.952 151.321C120.844 151.321 126.43 145.74 126.43 138.855C126.43 131.97 120.844 126.388 113.952 126.388C107.061 126.388 101.475 131.97 101.475 138.855C101.475 145.74 107.061 151.321 113.952 151.321Z"
                                    fill="#42B0DC"
                                />
                                <g id="head">
                                    <path
                                        id="ab6171fa-7d69-4734-b81c-8dff60f9761b"
                                        d="M112.722 97.5813C127.761 97.5813 139.953 85.4008 139.953 70.3754C139.953 55.3499 127.761 43.1694 112.722 43.1694C97.6825 43.1694 85.4907 55.3499 85.4907 70.3754C85.4907 85.4008 97.6825 97.5813 112.722 97.5813Z"
                                        fill="#FFB6B6"
                                    />
                                    <path
                                        id="bf427902-b9bf-4946-b5d7-5c1c7e04535e"
                                        d="M136.569 50.4284C136.569 50.4284 143.691 36.8255 128.022 35.5888C128.022 35.5888 114.664 23.4826 101.048 33.3756C101.048 33.3756 93.6216 33.3756 89.5604 41.772C89.5604 41.772 83.7193 39.5588 82.4357 45.4819C82.4357 45.4819 78.1616 57.8482 82.4357 68.9779C86.7098 80.1077 88.1295 81.3443 88.1295 81.3443C88.1295 81.3443 81.1063 58.0103 98.2013 56.7736C115.296 55.5369 134.426 44.8636 135.851 58.4665C137.275 72.0695 139.419 75.4211 139.419 75.4211C139.419 75.4211 152.951 55.9933 136.569 50.4284Z"
                                        fill="#2F2E41"
                                    />
                                    <path
                                        id="Vector_64"
                                        d="M145.745 68.8888H143.752C143.752 53.8367 128.842 43.9733 113.8 43.9653C111.612 43.9589 109.429 44.171 107.283 44.5984C104.868 45.0803 102.361 45.6576 99.8973 46.4103C90.1842 49.3848 81.1765 55.1349 81.1765 68.8888C81.1744 71.5599 81.5146 74.2203 82.1888 76.805L80.2597 77.3068C79.5442 74.5579 79.1826 71.7291 79.1836 68.8888C79.1836 56.871 84.1219 48.6163 92.6513 43.6945C97.1352 41.1062 102.608 39.4377 108.877 38.6254C111.486 38.2786 114.124 38.1986 116.749 38.3865C134.047 39.6289 145.745 52.881 145.745 68.8888Z"
                                        fill="#42B0DC"
                                    />
                                    <path
                                        id="Vector_65"
                                        d="M149.065 69.9918L148.28 64.194C148.086 62.7298 147.38 61.3813 146.287 60.3872C145.585 59.7391 144.745 59.2589 143.83 58.983C142.915 58.707 141.949 58.6425 141.006 58.7944L139.758 58.9656C139.417 59.0105 139.088 59.1227 138.79 59.2956C138.493 59.4685 138.232 59.6988 138.025 59.973C137.815 60.2464 137.662 60.5584 137.573 60.8913C137.485 61.2241 137.464 61.5711 137.51 61.9123L139.324 75.268C139.399 75.8124 139.643 76.3194 140.022 76.7175C140.401 77.1155 140.896 77.3844 141.436 77.486C141.596 77.518 141.759 77.5341 141.923 77.5338C142.04 77.5338 142.157 77.5258 142.273 77.5099L143.668 77.3227C144.519 77.21 145.337 76.9247 146.073 76.4844C146.81 76.044 147.448 75.458 147.949 74.7623C148.057 74.611 148.16 74.4557 148.256 74.2964C149.003 72.9934 149.288 71.4768 149.065 69.9918Z"
                                        fill="#42B0DC"
                                    />
                                    <path
                                        id="Vector_66"
                                        d="M138.953 59.2085C138.587 59.3861 138.269 59.6482 138.025 59.973C137.815 60.2464 137.662 60.5584 137.573 60.8913C137.485 61.2241 137.464 61.5711 137.51 61.9123L139.324 75.268C139.399 75.8123 139.643 76.3194 140.022 76.7175C140.401 77.1155 140.896 77.3844 141.436 77.486L138.953 59.2085Z"
                                        fill="white"
                                    />
                                    <path
                                        id="Vector_67"
                                        d="M149.065 69.9918L148.279 64.194C148.086 62.7298 147.38 61.3813 146.287 60.3871L148.092 73.6752C148.119 73.8885 148.174 74.0973 148.256 74.2964C149.003 72.9934 149.288 71.4767 149.065 69.9918Z"
                                        fill="#3F3D56"
                                    />
                                    <path
                                        id="Vector_68"
                                        d="M142.859 74.1692C143.404 74.0953 143.679 72.7988 143.471 71.2733C143.264 69.7478 142.654 68.5711 142.109 68.645C141.564 68.7189 141.289 70.0154 141.497 71.5409C141.704 73.0664 142.314 74.2431 142.859 74.1692Z"
                                        fill="#3F3D56"
                                    />
                                    <path
                                        id="Vector_69"
                                        d="M141.663 67.0016C142.209 66.9277 142.483 65.6312 142.276 64.1057C142.068 62.5802 141.458 61.4035 140.913 61.4774C140.368 61.5513 140.094 62.8478 140.301 64.3733C140.508 65.8988 141.118 67.0755 141.663 67.0016Z"
                                        fill="#3F3D56"
                                    />
                                    <path
                                        id="Vector_70"
                                        d="M149.236 69.789C149.782 69.7151 150.056 68.4185 149.849 66.893C149.642 65.3676 149.032 64.1908 148.486 64.2647C147.941 64.3386 147.667 65.6352 147.874 67.1607C148.081 68.6861 148.691 69.8629 149.236 69.789Z"
                                        fill="#3F3D56"
                                    />
                                    <path
                                        id="Vector_71"
                                        d="M113.799 43.9653C111.611 43.9589 109.428 44.171 107.282 44.5984C104.867 45.0803 102.36 45.6577 99.8969 46.4103C96.0507 46.2231 93.2208 45.2276 92.6509 43.6945C97.1348 41.1062 102.607 39.4377 108.877 38.6254C111.486 38.2786 114.123 38.1986 116.749 38.3865C117.026 38.5492 117.267 38.7689 117.454 39.0311C117.64 39.2933 117.77 39.592 117.833 39.9076C118.02 41.2934 116.438 42.7667 113.799 43.9653Z"
                                        fill="#3F3D56"
                                    />
                                    <path
                                        id="Vector_72"
                                        d="M88.3546 82.7064L85.2856 69.96C85.1762 69.5001 84.9332 69.0828 84.5871 68.7604C84.2411 68.4381 83.8074 68.2251 83.3406 68.1481C83.2087 68.1276 83.0755 68.117 82.942 68.1163C82.754 68.1169 82.5667 68.1397 82.384 68.184L81.1963 68.4707C79.667 68.8402 78.3469 69.8007 77.5257 71.1415C76.7045 72.4823 76.4493 74.0938 76.816 75.6224L78.191 81.3406C78.505 82.6868 79.2527 83.8927 80.3194 84.7731C80.4922 84.9125 80.6733 85.0415 80.8615 85.1594C81.5296 85.5777 82.2748 85.858 83.0531 85.9839C83.8315 86.1097 84.6271 86.0785 85.3932 85.892L86.577 85.6053C86.89 85.5324 87.1844 85.3954 87.4419 85.2031C87.8247 84.9243 88.1158 84.538 88.2784 84.0935C88.4409 83.6489 88.4674 83.1661 88.3546 82.7064Z"
                                        fill="#42B0DC"
                                    />
                                    <path
                                        id="Vector_73"
                                        d="M81.9208 80.3348C82.4558 80.2061 82.5973 78.8885 82.2368 77.3918C81.8763 75.8951 81.1503 74.786 80.6153 74.9147C80.0802 75.0433 79.9387 76.3609 80.2992 77.8576C80.6597 79.3544 81.3857 80.4634 81.9208 80.3348Z"
                                        fill="#3F3D56"
                                    />
                                    <path
                                        id="Vector_74"
                                        d="M77.9349 81.5294C78.47 81.4008 78.6115 80.0832 78.251 78.5865C77.8904 77.0897 77.1645 75.9807 76.6294 76.1093C76.0944 76.2379 75.9529 77.5556 76.3134 79.0523C76.6739 80.549 77.3999 81.658 77.9349 81.5294Z"
                                        fill="#3F3D56"
                                    />
                                    <path
                                        id="Vector_75"
                                        d="M88.55 82.6586L85.481 69.9122C85.3614 69.4146 85.0976 68.9633 84.7226 68.6148C84.3475 68.2664 83.8779 68.0361 83.3726 67.953L87.5616 85.3624C87.9753 85.0597 88.2899 84.6414 88.4658 84.1601C88.6418 83.6789 88.671 83.1564 88.55 82.6586V82.6586Z"
                                        fill="white"
                                    />
                                    <path
                                        id="Vector_76"
                                        d="M77.5092 73.0978C77.4245 72.7433 77.2656 72.4108 77.0429 72.1222C76.6115 73.2384 76.5322 74.4598 76.8157 75.6224L78.1908 81.3406C78.5048 82.6868 79.2525 83.8927 80.3192 84.7731L77.5092 73.0978Z"
                                        fill="#3F3D56"
                                    />
                                </g>
                            </g>
                            <defs>
                                <linearGradient
                                    id="paint0_linear_101_4"
                                    x1="364.693"
                                    y1="133"
                                    x2="1.58171e-05"
                                    y2="134.595"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop
                                        stopColor="#43B3DA"
                                        stopOpacity="0.78"
                                    />
                                    <stop
                                        offset="0.0001"
                                        stopColor="#43B5D8"
                                        stopOpacity="0.99"
                                    />
                                    <stop
                                        offset="0.944483"
                                        stopColor="#0F57CC"
                                        stopOpacity="0.72"
                                    />
                                </linearGradient>
                                <clipPath id="clip0_101_4">
                                    <rect
                                        width="339"
                                        height="295"
                                        fill="white"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    )}

                    {/* Section header */}
                    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                        <h1 className="h1">Une idée de projet ? </h1>
                    </div>

                    {/* Contact form */}
                    <form
                        onSubmit={onSubmit}
                        method="POST"
                        name="contact"
                        className="max-w-xl mx-auto"
                        data-netlify="true"
                    >
                        <input type="hidden" name="form-name" value="contact" />

                        <div className="flex flex-wrap -mx-3 mb-4">
                            <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                                <label
                                    className="block text-gray-800 text-sm font-medium mb-1"
                                    htmlFor="first-name"
                                >
                                    Prénom{' '}
                                    <span className="text-red-600">*</span>
                                </label>
                                <input
                                    id="firstName"
                                    type="text"
                                    name="firstName"
                                    className="form-input w-full text-gray-800"
                                    placeholder="Votre prénom"
                                    onChange={onChange}
                                    onFocus={onFocus}
                                    required
                                />
                            </div>
                            <div className="w-full md:w-1/2 px-3">
                                <label
                                    className="block text-gray-800 text-sm font-medium mb-1"
                                    htmlFor="last-name"
                                >
                                    Nom <span className="text-red-600">*</span>
                                </label>
                                <input
                                    id="lastName"
                                    type="text"
                                    name="lastName"
                                    className="form-input w-full text-gray-800"
                                    placeholder="Votre nom"
                                    onChange={onChange}
                                    onFocus={onFocus}
                                    required
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-4">
                            <div className="w-full px-3">
                                <label
                                    className="block text-gray-800 text-sm font-medium mb-1"
                                    htmlFor="email"
                                >
                                    adresse e-mail{' '}
                                    <span className="text-red-600">*</span>
                                </label>
                                <input
                                    id="email"
                                    type="email"
                                    name="email"
                                    className="form-input w-full text-gray-800"
                                    placeholder="Votre adresse e-mail"
                                    onChange={onChange}
                                    onFocus={onFocus}
                                    required
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-4">
                            <div className="w-full px-3">
                                <label
                                    className="block text-gray-800 text-sm font-medium mb-1"
                                    htmlFor="message"
                                >
                                    Message
                                </label>
                                <textarea
                                    id="message"
                                    rows="4"
                                    className="form-textarea w-full text-gray-800"
                                    placeholder="Décrivez votre projet"
                                    onChange={onChange}
                                    onFocus={onFocus}
                                    name="message"
                                ></textarea>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mt-4">
                            <div className="w-full px-3">
                                <button className="btn text-white bg-blue-500 hover:bg-blue-700 w-full">
                                    {isSending && (
                                        <svg
                                            className="animate-spin h-5 w-5 mr-3 bg-transparent"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-10"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            ></path>
                                        </svg>
                                    )}
                                    {}
                                    {isError &&
                                        "Une erreur est survenue lors de l'envoi du formulaire"}
                                    {isSuccess &&
                                        ' Merci ! Votre message a bien été envoyé'}
                                    {!isSending &&
                                        !isError &&
                                        !isSuccess &&
                                        'Envoyer'}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Cta

import React from 'react'

import Header from '../partials/Header'
import HeroHome from '../partials/HeroHome'
import FeaturesHome from '../partials/FeaturesHome'
import FeaturesBlocks from '../partials/FeaturesBlocks'
// import FeaturesWorld from '../partials/FeaturesWorld';
import PortFolio from '../partials/PortFolio'
import Cta from '../partials/Cta'
import Footer from '../partials/Footer'

function Home() {
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path
                    fill="rgba(51, 140, 245, 0.5)"
                    fillOpacity="0.5"
                    d="M0,64L40,64C80,64,160,64,240,96C320,128,400,192,480,202.7C560,213,640,171,720,176C800,181,880,235,960,245.3C1040,256,1120,224,1200,213.3C1280,203,1360,213,1400,218.7L1440,224L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"
                ></path>
            </svg>
            {/*  Site header */}
            <Header />
            {/*  Page content */}
            <main className="flex-grow">
                {/*  Page sections */}
                <HeroHome />
                <FeaturesHome />
                <FeaturesBlocks />
                <PortFolio />
                <Cta />
            </main>

            {/*  Site footer */}
            <Footer />
        </div>
    )
}

export default Home

import { useEffect, useRef } from 'react'
import { motion, useInView, useAnimation } from 'framer-motion'

const Reveal = ({ children, width = 'fit-content', hiddenVariant = { opacity: 0, y: 75 }, visibleVariant ={ opacity: 1, y: 0 } }) => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })

    const controls = useAnimation()


    useEffect(() => {
      if(isInView)
        controls.start("visible")

    }, [isInView])

    return (
        <div ref={ref} style={{position: "relative", overflow: "hidden"}}>
            <motion.div
                variants={{
                    hidden: hiddenVariant,
                    visible: visibleVariant,
                }}
                initial="hidden"
                animate={controls}
                transition={{ duration: 0.5, delay: 0.25 }}
            >
                {children}
            </motion.div> 
        </div>
    )
}

export default Reveal

import React from 'react'
// import Transition from '../utils/Transition.js';
import { useTranslation } from 'react-i18next';
import ProfilImage from '../images/profile-picture-4.webp';
import Reveal from '../components/Reveal';

function FeaturesHome() {
    // const [tab, setTab] = useState(1);

    // const tabs = useRef(null);

    // const heightFix = () => {
    //   if (tabs.current.children[tab]) {
    //     tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + 'px'
    //   }
    // }

    // useEffect(() => {
    //   heightFix()
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [tab])

    const { t } = useTranslation();


    return (
        <section className="relative mt-16 md:mt-32 lg:mt-64" id="a-propos">
            {/* Section background (needs .relative class on parent and next sibling elements) */}
            <div
                className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-400 pointer-events-none h-100 py-6"
                aria-hidden="true"
            ></div>
            {/* <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div> */}

            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pt-12 md:pt-20">
                    {/* Section header */}
                    <Reveal>
                        <div>
                            <img src={ProfilImage} alt="profile" width={150} className='rounded-full mx-auto' />
                        </div>
                    </Reveal>
                    <Reveal>
                        <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                            <h1 className="h2 mb-5 text-white">{t('ABOUT-TITLE')}</h1>
                            <p className="text-m text-white text-left mt-16">
                                {t('ABOUT-FIRST')}
                            </p>
                            <br />
                            <p className="text-m text-white text-left">
                                {t('ABOUT-SECOND')}

                            </p>
                            <br />
                            <p className="text-m text-white text-left">
                                {t('ABOUT-THIRD')}

                            </p>
                            <br />
                            <p className="text-m text-white text-left">
                                {t('ABOUT-FOURTH')}

                            </p>
                        </div>

                    </Reveal>

                    {/* Top image */}
                    {/* <div className="pb-12 md:pb-16">
            <img src={require('../images/features-top-image.png').default} width="1104" height="325" alt="Features top" />
          </div> */}
                </div>
            </div>
        </section>
    )
}

export default FeaturesHome

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
 



const options = [
  { value: "fr", label: "🇫🇷" },
  { value: "en", label: "🇬🇧" },
  { value: "jp", label: "🇯🇵" }
];

const LangSelector = () => {
  const { i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState('fr');



 
  const changeLanguage = (event) => {
    setSelectedLang(event.target.value);
    i18n.changeLanguage(event.target.value);
  }
 
  return (
    <select onChange={changeLanguage} className="cursor-pointer form-select appearance-none border-none bg-transparent text-1xl">
      {
        options.map((item, idx) => (
          <option key={idx+1} value={item.value} className="text-3xl">{item.label}</option>
        ))
      }
    </select>
  )
}
 
export default LangSelector;
import React, { lazy } from 'react'
import portfolioData from '../data/portfolio-data.json'

function Portfolio() {

    const PortfolioItem  = lazy(() => import('../components/PortfolioItem'))

    
    return (
        <section id="portfolio" className="relative mt-16 lg:mt-64">

            <div
                className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-400 pointer-events-none h-100 py-8 -z-50"
                aria-hidden="true"
            />

            <div className="max-w-6xl mx-auto px-4 sm:px-6 -z-10" id="projets">

                <div className="py-12 md:py-20">
                    {/* Section header */}
                    <h2 className="h2 text-center my-5 z-[99] text-white">Portfolio</h2>

                    <div className="max-w-sm mx-auto md:max-w-none grid md:auto-rows-auto grid-cols-1 md:grid-cols-3 pt-5 gap-y-8 md:gap-4">

                        {portfolioData.map((project, index) => (
                            <PortfolioItem project={project} index={index} key={project.id} />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Portfolio

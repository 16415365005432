import React from 'react'
import Header from '../partials/Header';
import Footer from '../partials/Footer';


const MentionsLegales = () => {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
            <Header isMention={true}/>
            <main className='flex-grow mt-32 mb-32'>
                <section className='max-w-6xl mx-auto px-4 sm:px-6 mt-auto'>
                        <h1 className='text-3xl text-gray-900 md:text-4xl font-extrabold leading-tighter tracking-tighter mb-2'>Mentions légales</h1>
                        <article className='mb-9'>
                            <h3 className='text-2xl text-gray-900 md:text-3xl font-extrabold leading-tighter tracking-tighter mb-2'>Sommaire</h3>
                            <ul>
                                <li><a href="#cookie" className='hover:underline'>Cookie</a></li>
                                <li><a href="#credits" className='hover:underline'>Crédits</a></li>
                            </ul>
                        </article>
                        <article className='mb-9' id="#cookie">
                            <h3 className='text-2xl text-gray-900 md:text-3xl font-extrabold leading-tighter tracking-tighter mb-2'>Cookie</h3>
                            <p>Aucun tracking ni cookie sur ce site</p>
                        </article>
                        <article className='mb-9'>
                            <h3 className='text-2xl text-gray-900 md:text-3xl font-extrabold leading-tighter tracking-tighter mb-2'>Crédits</h3>
                            <p>Conception, logo et intégration par Stéphane Fok yin</p>
                        </article>
                </section>  
            </main>
            <Footer/>
    </div>
  )
}

export default MentionsLegales
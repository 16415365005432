import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom';

import './css/style.scss';

import AOS from 'aos';
import Sticky from 'sticky-js';
import { focusHandling } from 'cruip-js-toolkit';

import Home from './pages/Home';
import PageNotFound from './pages/PageNotFound';
import MentionsLegales from './pages/MentionsLegales';

function App() {

  const location = useLocation();


  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/mentions-legales">
            <MentionsLegales/>
        </Route>
        <Route path="/*">
          <PageNotFound/>
        </Route>
      </Switch>
    </>
  );
}

export default App;
